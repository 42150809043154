<template>
  <v-container>
    <LogoHeader></LogoHeader>
    <v-row justify="center">
      <v-card class="mt-6 ml-2 mr-2">
        <v-card-title
        ><v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-uppercase
              btn-default
              bg-gradient-default
              py-2
              px-6
              me-2
              mt-4
              mb-2
            "
            color="#5e72e4"
            to="/"
        ><v-icon>fas fa-chevron-left</v-icon> Strona główna</v-btn
        >
          <span class="text-h5 text-center">Instrukcja usuwania konta</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <account-removal-text></account-removal-text>
<!--          <tos-text></tos-text>-->
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import TosText from "@/components/util/TOS.vue";
import LogoHeader from "@/components/util/LogoHeader.vue";
import AccountRemovalText from '@/components/util/RemovalInstructions.vue'
export default {
  name: "TOS",
  components: {
    AccountRemovalText,
    TosText,
    LogoHeader,
  },
};
</script>

<style>
</style>
