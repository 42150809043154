<template>
  <div>
    <h4></h4>
    <p>Aby usunąć konto należy wysłać emaila na adres <a href="mailto:app@treningfitness.com?subject=Usunięcie Konta">app@treningfitness.com</a> z tematem Usunięcie Konta z adresu który jest przypisany do konta i wyrażo
    </p>
  </div>
</template>

<script>
export default {
  name: 'account-removal-text',
}
</script>

<style scoped>
</style>